














import { Component, Prop, Vue } from 'vue-property-decorator';
const iconList = require('../utils/icon.json');
@Component({
  name: 'ComIconDialog'
})
export default class extends Vue {
  @Prop({ default: true }) private visible!: Boolean;
  @Prop({ default: '' }) private value!: string;
  private icons: string[] = iconList;

  handlerSelectIcon(iconName) {
    this.$emit('input', iconName);
    this.$emit('update:visible', false);
  }
  get dialogVisible() {
    return this.visible;
  }
  set dialogVisible(newValue) {
    this.$emit('update:visible', false);
  }
}
