var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("lang_select_icon"),
            width: "60%",
            top: "0vh",
            visible: _vm.dialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "ul",
            { staticClass: "icon-list" },
            _vm._l(_vm.icons, function (iconName) {
              return _c(
                "li",
                {
                  key: iconName,
                  class: { activeIcon: iconName === _vm.value },
                  on: {
                    click: function ($event) {
                      return _vm.handlerSelectIcon(iconName)
                    },
                  },
                },
                [_c("i", { class: iconName })]
              )
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }